import { EventNames, TrackingPort } from '../../ports'
import {
  CookieBannerEvents,
  CookieBannerLabelList,
  CookieBannerMoveEvent,
  PreferenceCenterEvents,
  PreferenceCenterLabelList,
  TrackingLabels,
} from './tracking-labels'
import { LoggingServicePort } from '../../../shared/ports'
import { ConsentProviderPort } from '../../../onetrust-sdk/ports'

export class TrackingService {
  constructor(
    private readonly trackingPort: TrackingPort<TrackingLabels>,
    private readonly consentProvider: ConsentProviderPort,
    private readonly log: LoggingServicePort
  ) {}

  trackOTFirstLayerView(): void {
    this.trackPageImpressionView()
    if (this.consentProvider.hasConsentBeenGiven()) {
      this.log.logMessageWithId(
        'TRACKING',
        '1st Layer "view" event tracked, but consent was already given'
      )
    }
  }

  trackOTFirstLayerAgree(): void {
    this.trackCookieBannerEvent('agree')
    if (this.consentProvider.hasConsentBeenGiven()) {
      this.log.logMessageWithId(
        'TRACKING',
        '1st Layer "agree" event tracked, but consent was already given'
      )
    }
  }

  trackOTFirstLayerDisagree(): void {
    this.trackCookieBannerEvent('disagree')
  }

  trackOTFirstLayerSettings(): void {
    this.trackCookieBannerEvent('settings')
  }

  trackOTFirstLayerScroll(): void {
    this.trackCookieBannerEvent(`read_policy`)
  }

  trackOTFirstLayerTextLink(link: string): void {
    return this.trackCookieBannerMove(`textLink_${link}`)
  }

  trackOTPreferenceCenterView(): void {
    this.trackPreferenceCenterEvent('view')
  }

  trackOTPreferenceCenterAgreeAll(): void {
    this.trackPreferenceCenterEvent('agree_All')
  }

  trackOTPreferenceCenterDisagreeAll(): void {
    this.trackPreferenceCenterEvent('disagree_All')
  }

  trackOTPreferenceCenterAgreeMySelection(): void {
    this.trackPreferenceCenterEvent('agree_MySelection')
  }

  private trackPageImpressionView(): void {
    void this.trackingPort.track(
      EventNames.PageImpression,
      this.createCookieBannerFeatureList('view')
    )
  }

  private trackCookieBannerEvent(event: CookieBannerEvents): void {
    void this.trackingPort.track(
      EventNames.Event,
      this.createCookieBannerFeatureList(event)
    )
  }

  private trackCookieBannerMove(event: CookieBannerMoveEvent): void {
    void this.trackingPort.track(
      EventNames.Move,
      this.createCookieBannerFeatureList(event)
    )
  }

  private createCookieBannerFeatureList(
    event: CookieBannerEvents | CookieBannerMoveEvent
  ): CookieBannerLabelList {
    return { ot_CookieBanner: [event] }
  }

  private trackPreferenceCenterEvent(event: PreferenceCenterEvents): void {
    const featureList: PreferenceCenterLabelList = {
      ot_CookieBannerSettings: [event],
    }

    void this.trackingPort.track(EventNames.Event, featureList)
  }
}
