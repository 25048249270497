import { submitEvent, submitMove, submitMerge } from '@otto-ec/tracking-bct'
import { EventNames, TrackingPort } from '../ports'
import { LoggingServicePort } from '../../shared/ports'

export class TrackingBctAdapter<Labels extends Record<string, [string]>>
  implements TrackingPort<Labels>
{
  constructor(private readonly logger: LoggingServicePort) {}

  async track(eventName: EventNames, labels: Labels): Promise<void> {
    try {
      switch (eventName) {
        case EventNames.PageImpression:
          return await submitMerge<Labels>(labels)
        case EventNames.Event:
          return await submitEvent<Labels>(labels)
        case EventNames.Move:
          return await submitMove<Labels>(labels)
      }
    } catch (e) {
      this.logTrackingError(eventName, labels, e)
      return
    }
  }

  private logTrackingError(eventName: EventNames, labels: Labels, e: unknown) {
    this.logger.logMessage(`Error tracking event ${eventName}.`, labels, e)
  }
}
